<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="员工姓名/手机号" style="width: 200px;;margin-right: 10px;"></el-input>
			</div>
			<div class="filter-item">
				<label class="label">时间筛选: </label>
				<el-date-picker v-model="TimeValue" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" type="daterange" range-separator="至"
				 start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
			</div>
		</div>
		<div class="table-container">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column label="员工">
					<template slot-scope="scope">
						<div style="display:flex;flex-direction: row;align-items: center">
							<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-if="scope.row.WxHeadUrl==''"
							src='https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png' />
							<img style="width:50px;height:50px;border-radius:100%;object-fit:cover;border:1px solid #ddd;" v-else :src='scope.row.WxHeadUrl' />
							<span style="margin-left:5px;">{{scope.row.MallEmployeeName}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="手机号" prop="Phone"></el-table-column>
				<el-table-column prop="AccessCount" label="接入人数"></el-table-column>
				<el-table-column prop="AllCount" label="总会话数"></el-table-column>
				<el-table-column prop="InvalidCount" label="无效会话数"></el-table-column>
				<el-table-column prop="ValidCount" label="有效会话数"></el-table-column>
				<el-table-column prop="ActiveCount" label="主动发起会话数"></el-table-column>
				<el-table-column prop="AvgFirstReplySeconds" label="平均首次响应时长"></el-table-column>
				<el-table-column prop="AvgReplySeconds" label="平均响应时长"></el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		customWorkloadList
	} from '@/api/goods'
	export default {
		data() {
			return {
				TimeValue: [],
				// pickerOptions0: {
				// 	disabledDate(time) {
				// 		return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
				// 	}
				// },
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() > Date.now()
					}
				},
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				searchKey: '',
				loading:true,
			}
		},
		created() {
			let yesterday = this.getDay(-1, '-');
			this.TimeValue=[yesterday,yesterday]
			this.getList()
		},
		methods: {
			getDay(num, str) {
				let today = new Date();
				let nowTime = today.getTime();
				let ms = 24 * 3600 * 1000 * num;
				today.setTime(parseInt(nowTime + ms));
				let oYear = today.getFullYear();
				let oMoth = (today.getMonth() + 1).toString();
				if (oMoth.length <= 1) oMoth = '0' + oMoth;
				let oDay = today.getDate().toString();
				if (oDay.length <= 1) oDay = '0' + oDay;
				return oYear + str + oMoth + str + oDay;
			},
			//获取数据
			async getList() {
				this.loading = true
				let startTime = this.TimeValue[0]? (this.TimeValue[0]+' '+'00:00:00'):''
				let endTime =this.TimeValue[1]? (this.TimeValue[1]+' '+'23:59:59'):''
				try {
					let data = {
						KeyWord: this.searchKey,
						StartTime:startTime,
						EndTime:endTime,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					
					let result = await customWorkloadList(data)
					this.Total = result.Total;
					this.groupData = result.Results || [];
				} catch (e) {
					console.log('e',e)
				} finally {
					this.loading = false
				}
			},
			goodsSearch() {
				this.currentPage = 1
				this.getList();
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>

</style>
